
import React from 'react';
import iconoFondo from '../assets/pdfs/iconoFondo.png';
import logo from '../assets/pdfs/logoReporte.png';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import {
    formatMillisecondsToDate, nWithCommas, obtenerUltimoDiaDelMes,
    divideInformacion,
    processFechaGuion,
    calculaSalto,
    cambioTipoFondo,
    cambioEfectivoPlan
} from '../utils';
import { BASE_URL } from "../properties";
import {
    cambioEfectivo, obtenerFechaActualString, encryptAES, separarConceptos,
    agregaAportacion, capitalizeFirstLetter,
    eliminarAcentos, negativos, obtenerPrimerDiaDelMes, cambioEfectivoPdf
} from '../utils';
import { toogleLoad, setTokenSesion, } from "../redux/slices/appslice";
import { app } from '../firebaseContext';


function PdfPlanDePensiones({ movToShow, id, primerFecha }) {
    const dispatch = useDispatch();
    const appData = useSelector((state) => state.appData)
    const [saldosFecha, setSaldosFecha] = useState(false);
    const [cuentas, setCuentas] = useState([]);
    const [movimientos, setMovimientos] = useState([]);
    const [dataSaldos, setDataSaldos] = useState([]);
    const [aportacionesSeparadas, setAportacionesSeparadas] = useState(false);
    const [datosEmpleado, setDatosEmpleado] = useState(false);
    const [sumaSaldoInicial, setSumaSaldoInicial] = useState(0);



    useEffect(() => {

        if (appData.saldosFecha) {

            for (let saldo of appData.saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {

                        setSaldosFecha(saldo);
                    }
                }
            }
        }

        if (appData.saldosTrimestrales && appData.saldosTrimestrales.saldosAgrupadosXPlan && appData.saldosTrimestrales.saldosAgrupadosXPlan.length) {
            for (let saldos of appData.saldosTrimestrales.saldosAgrupadosXPlan) {
                if (saldos.Producto && saldos.Producto.TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {

                    let total_rendimientos = saldos.saldos.reduce((total, elemento) => total + elemento.rendimiento, 0);
                    let total_saldo = saldos.saldos.reduce((total, elemento) => total + elemento.saldo, 0);


                    setDataSaldos({
                        ...dataSaldos,
                        saldos: saldos.saldos,
                        total_rendimientos: total_rendimientos,
                        total_saldo: total_saldo
                    })
                }
            }
        }

        if (appData.saldosTrimestrales && appData.saldosTrimestrales.saldosAgrupadosXCuenta) {

            for (let st of appData.saldosTrimestrales.saldosAgrupadosXCuenta) {
                if (st.Producto && st.Producto.TipoCliente.toUpperCase() === 'FONDO DE PENSIONES') {
                    if (st.saldos && st.saldos.length) {

                        setSumaSaldoInicial(parseFloat(st.saldos.reduce((accumulator, item) => accumulator + item.saldoini, 0)));
                        setAportacionesSeparadas(separarConceptos(st.saldos));
                    }
                }
            }
        }

    }, []);

    useEffect(() => {
        if (saldosFecha) {

            try {
                getSaldosPorCuenta();
            } catch (error) {

            }
        }
    }, [saldosFecha])

    useEffect(() => {
        if (movToShow && Object.keys(movToShow).length) {
            let movi = [];
            for (let movK of Object.keys(movToShow).reverse()) {
                for (let mov of movToShow[movK]) {
                    movi.push(mov);
                }
            }

            setMovimientos(movi);

        }
    }, [movToShow])



    const getTotalDerechos = () => {

        let total = 0;

        if (Object.keys(aportacionesSeparadas).length) {

            for (let k of Object.keys(aportacionesSeparadas)) {
                total += parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.deradq, 0));

            }
        }

        return total;

    }


    const getSaldosPorCuenta = async () => {
        dispatch(toogleLoad(true));

        const dataUserProducto = saldosFecha.Producto;

        if (dataUserProducto.length && appData.token && appData.tokenSesion) {

            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "IdCliente": dataUserProducto[0].IdCliente,
                "IdEmpleado": dataUserProducto[0].IdEmpleado,
                "FechaIni": "20240801",
                "FechaFin": obtenerFechaActualString(),
                "TodaLaHistoria": "True",
                "SistemaOperativo": "web",
                "AppID": "SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token,
                "DeviceToken": "dv1"
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/saldos/saldosYMovimientos`, requestOptions)
                .then(response => response.json())
                .then((data) => {


                    if (data.Datos && data.JWT && data.Datos && data.Datos.ListaCuentas && data.Datos.ListaMovimientos) {


                        setDatosEmpleado(data.Datos);
                        setCuentas(data.Datos.ListaCuentas);

                        //setMovimientos(data.Datos.ListaMovimientos);

                        dispatch(setTokenSesion(data.JWT.current_token))

                        dispatch(toogleLoad(false));


                    } else {
                        dispatch(toogleLoad(false));
                    }
                }).catch((e) => {

                    dispatch(toogleLoad(false));

                });
        }


    }

    const getPorcentaje = (derechosAd, saldoFin) => {

        if (derechosAd > 0) {
            return Math.round(parseFloat(derechosAd) / parseFloat(saldoFin) * 100)
        }
        return 0;
    }

    return (
        <div className="row" id={id}>
            <div className='col s4'>
                <img src={logo} alt="logo" style={{ maxHeight: '55px' }} />
            </div>
            <div className='col s8'>
                <div className='row'>
                    <div className='col s12 center-align' style={{
                        background: '#002c5d',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: 'white', height: '55px', paddingTop: '1rem'
                    }}>
                        Plan de Pensiones
                    </div>
                    <div className='col s12 center-align ft-12'>
                        Valores Mexicanos Casa de Bolsa, S.A. de C.V.
                    </div>
                </div>

            </div>
            <div className='col s12'>

            </div>

            <div className='col s8 offset-s4 ' style={{ color: '#002c5e', fontWeight: '600', fontSize: '14px' }}>
                {
                    Object.keys(movToShow).length && saldosFecha ?
                        `Periodo del ${formatMillisecondsToDate(primerFecha)} al ${obtenerUltimoDiaDelMes(formatMillisecondsToDate(primerFecha), saldosFecha.FechaOP)}`
                        : null
                }
            </div>

            <div className='col s8 offset-s4 mt-1' style={{ color: '#002c5e', fontWeight: 'bold', fontSize: '18px' }}>
                Reporte de Saldos y Movimientos
            </div>

            <div className='col s12 mt-1'>

            </div>

            <div className='col s4'>
                <div className='row'>
                    <div className='col s12'>
                        <span className='secondaty_cyan boldText ft-12'>{appData.user.Nombre + ' ' + appData.user.ApPaterno + ' ' + appData.user.ApMaterno}</span>
                    </div>

                    <div className='col s12'>
                        <span className='boldText ft-12'># Colaborador:  {datosEmpleado ? datosEmpleado.Clave : ''}</span>
                    </div>
                    <div className='col s12'>
                        <span className='boldText ft-12'>RFC: {datosEmpleado ? datosEmpleado.RFC : ''}</span>
                    </div>
                    <div className='col s12'>
                        <span className='boldText ft-12'>CURP: {datosEmpleado ? datosEmpleado.CURP : ''}</span>
                    </div>
                </div>

            </div>
            <div className='col s8 ft-12' >
                <div className='row'>
                    <div className='col s12'>
                        <span className='boldText secondaty_cyan ft-16'>Mi Plan</span>
                    </div>
                    <div className='col s6 left-align boldText '>
                        Saldo Inicial:
                    </div>
                    <div className='col s6 right-align '>
                        ${nWithCommas(sumaSaldoInicial.toFixed(2))}
                    </div>

                    <div className='col s6 left-align boldText'>Aportaciones:</div>
                    <div className='col s6 right-align ' >${nWithCommas(parseFloat(saldosFecha.aportaciones).toFixed(2))}</div>
                    <div className='col s6 left-align boldText'>Ajustes:</div>
                    <div className='col s6 right-align '>{negativos(`$${nWithCommas(parseFloat(saldosFecha.Retiros).toFixed(2))}`)}</div>
                    <div className='col s6 left-align boldText'>Plusvalía/Minusvalía:</div>
                    <div className='col s6 right-align '>{negativos(`$${nWithCommas(parseFloat(saldosFecha.rendimiento).toFixed(2))}`)}</div>
                    <div className='col s6 left-align boldText'>Saldo Total:</div>
                    <div className='col s6 right-align '>${nWithCommas(parseFloat(saldosFecha.SubTotal).toFixed(2))}</div>


                    <div className='col s12 white-text mt-1  right-align'>
                        <div style={{ background: '#ff606b', height: '20px', paddingTop: '1px' }}>
                            <span className='boldText'>Derechos Adquiridos: </span> <span style={{ marginRight: '2px' }}>${nWithCommas(parseFloat(saldosFecha.DerechoAdquirido).toFixed(2))}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col s4' style={{ paddingLeft: '0px' }}>
                <div className='boldText white-text' style={{
                    background: '#002c5d',
                    paddingLeft: '3px'
                }}>
                    Resumen de Mi Plan
                </div>
            </div>
            <div className='col s8'>
            </div>

            <div className='col s12 mt-1'></div>

            <div className='col s9' style={{ paddingLeft: '0px' }}>
                <div className='col s2 ft-10 center-align containerResumenBluePP'>
                    Concepto
                </div>
                <div className='col s2 ft-10 center-align containerResumenBluePP'>
                    Saldo Inicial
                </div>
                <div className='col s2 ft-10 center-align containerResumenBluePP'>
                    Aportaciones
                </div>
                <div className='col s2 ft-10 center-align containerResumenBluePP'>
                    Ajustes
                </div>
                <div className='col s2 ft-10 center-align containerResumenBluePP'>
                    Rendimientos
                </div>
                <div className='col s2 ft-10 center-align containerResumenR'>
                    Saldo
                </div>
                {
                    Object.keys(aportacionesSeparadas).length ?
                        Object.keys(aportacionesSeparadas).map((k, i) => {
                            return (
                                <>
                                    <div className='col s2 ft-10 center-align containerResumenBlueDescPP' style={{ textTransform: 'capitalize' }}>
                                        {capitalizeFirstLetter(agregaAportacion(k))}
                                    </div>
                                    <div className='col s2 ft-10 center-align containerResumenBlueDescPP'>
                                        {
                                            aportacionesSeparadas[k].length ?
                                                `$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.saldoini, 0)).toFixed(2))}`
                                                : null
                                        }
                                    </div>
                                    <div className='col s2 ft-10 center-align containerResumenBlueDescPP'>
                                        {
                                            aportacionesSeparadas[k].length ?
                                                `${parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.aportaciones, 0)) >= 0 ? '' : ''}$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.aportaciones, 0)).toFixed(2))}`
                                                : null
                                        }
                                    </div>
                                    <div className='col s2 ft-10 center-align containerResumenBlueDescPP'>
                                        {
                                            aportacionesSeparadas[k].length ?
                                                negativos(`$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.retiros, 0)).toFixed(2))}`)
                                                : null
                                        }
                                    </div>
                                    <div className='col s2 ft-10 center-align containerResumenBlueDescPP'>
                                        {
                                            aportacionesSeparadas[k].length ?
                                                negativos(`$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.rendimiento, 0)).toFixed(2))}`)
                                                : null
                                        }
                                    </div>
                                    <div className='col s2 ft-10 center-align containerResumenRDesc'>
                                        {
                                            aportacionesSeparadas[k].length ?
                                                `${parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.saldo, 0)) >= 0 ? '' : ''}$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.saldo, 0)).toFixed(2))}`
                                                : null
                                        }

                                    </div>
                                </>
                            )
                        })


                        : null
                }
            </div>


            <div className='col s3'>
                <div className='col s12 ft-10 center-align containerResumenBlue'>
                    Derechos Adquiridos
                    <div className='row'>
                        <div className='col s6 center-align'>
                            $
                        </div>
                        <div className='col s6 center-align'>
                            %
                        </div>
                    </div>
                </div>

                {
                    Object.keys(aportacionesSeparadas).length ?
                        Object.keys(aportacionesSeparadas).map((k, i) => {
                            return (
                                <>
                                    <div className='col s6 ft-8 center-align containerResumenBlueDesc'>
                                        {
                                            aportacionesSeparadas[k].length ?
                                                `${parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.deradq, 0)) >= 0 ? '' : ''}$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.deradq, 0)).toFixed(2))}`
                                                : null
                                        }
                                    </div>

                                    <div className='col s6 ft-8 center-align containerResumenBlueDesc'>
                                        {getPorcentaje(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.deradq, 0)),
                                            parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.saldo, 0)))} %
                                    </div>

                                </>
                            )
                        })
                        : null
                }
            </div>

            <div className='col s5 offset-s7 white-text mt-1  left-align' >

                <div style={{ background: '#ff606b', height: '25px', paddingTop: '1px', fontWeight: '600', paddingLeft: '10px' }}>
                    <span className='boldText'>Derechos Adquiridos: </span> <span style={{ marginRight: '2px' }}>${nWithCommas(parseFloat(getTotalDerechos()).toFixed(2))}</span>
                </div>
            </div>


            <div className='col s12 mt-2'></div>
            <div className='col s4' style={{ paddingLeft: '0px' }}>
                <div className='boldText white-text' style={{
                    background: '#002c5d',
                    paddingLeft: '3px'
                }}>
                    Portafolio de Mi Plan
                </div>
            </div>
            <div className='col s8'>
            </div>
            <div className='col s12 mt-1'>

            </div>

            <div className='col s2'>
                <div className='row'>
                    <div className='col s12 center-align'>
                        <img src={iconoFondo} alt="iconoFondo" />
                    </div>
                </div>
            </div>
            <div className='col s10'>
                <div className='row'>
                    <div className='col s12 left-align'>
                        Aquí podrás conocer los fondos en donde está invertido tu  <span className='boldText' style={{ color: '#002c5d' }}>Plan de Pensiones</span>.
                    </div>
                    <div className='col s12 left-align ft-11'>
                        Si deseas consultar el detalle de los fondos ingresa a www.operadoravalmex.mx
                    </div>
                </div>
            </div>


            <div className='col s12' style={{ marginTop: '5px' }}></div>


            <div className='col s6 center-align containerResumenBlueFondoPP boldText' >
                Fondo
            </div>

            <div className='col s6 center-align containerResumenBlueFondoPP boldText' >
                Saldo
            </div>

            {
                dataSaldos.saldos && dataSaldos.saldos.length ?
                    divideInformacion(dataSaldos.saldos
                        .filter((f) => parseFloat(f.saldo) > 0.01), 6, 28).map((v, i) => {
                            return v.map((j, h) => {
                                return (
                                    <>
                                        <div className='col s6 ft-10 center-align containerResumenBlueDescFondo'>
                                            {cambioTipoFondo(cambioEfectivo(j.plan),'FONDO DE PENSIONES')} 
                                        </div>
                                        <div className='col s6 ft-10 center-align containerResumenBlueDescFondo'>
                                            $ {nWithCommas(Math.round(j.saldo).toFixed(2))} 
                                        </div>

                                        {
                                            i === 0 ?
                                                v.length >= 3 && v.length <= 6 && h == v.length - 1 ?
                                                    <>
                                                        <div className='col s12 html2pdf__page-break'></div>
                                                        <div className='col s12' style={{ marginTop: '0.5rem' }} ></div>

                                                        {
                                                            divideInformacion(
                                                                dataSaldos.saldos
                                                                    .filter((f) => parseFloat(f.saldo) > 0.01), 6, 28)
                                                                .length > 1 ?
                                                                <div className='col s6 center-align containerResumenBlueFondoPP boldText' >
                                                                    Fondo
                                                                </div>
                                                                : null
                                                        }

                                                        {
                                                            divideInformacion(
                                                                dataSaldos.saldos
                                                                    .filter((f) => parseFloat(f.saldo) > 0.01), 6, 28)
                                                                .length > 1 ?
                                                                <div className='col s6 center-align containerResumenBlueFondoPP boldText' >
                                                                    Saldo
                                                                </div>
                                                                : null
                                                        }


                                                    </>
                                                    : null
                                                : h == v.length - 1 && v.length > 21 ?
                                                    <>
                                                        <div className='col s12 html2pdf__page-break'></div>
                                                        <div className='col s12' style={{ marginTop: '0.5rem' }} ></div>
                                                        <div className='col s6 center-align containerResumenBlueFondo boldText' >
                                                            Fondo
                                                        </div>

                                                        <div className='col s6 center-align containerResumenBlueFondo boldText' >
                                                            Saldo
                                                        </div>
                                                    </>
                                                    : null
                                        }

                                    </>
                                )
                            })

                        })
                    : null
            }


            <div className='col s12 mt-1'></div>

            <div className='col s4' style={{ paddingLeft: '0px' }}>
                <div className='boldText white-text' style={{
                    background: '#002c5d',
                    paddingLeft: '3px'
                }}>
                    Movimientos de Mi Plan
                </div>
            </div>
            <div className='col s8'>
            </div>

            <div className='col s12 mt-1'></div>
            <div className='col s3 ft-10 center-align containerResumenBlue'>
                Fecha
            </div>
            <div className='col s3 ft-10 center-align containerResumenBlue'>
                Cuenta
            </div>
            <div className='col s3 ft-10 center-align containerResumenBlue'>
                Tipo de Movimiento
            </div>
            <div className='col s3 ft-10 center-align containerResumenBlue'>
                Monto
            </div>
            {
                movimientos && movimientos.length && dataSaldos.saldos ?
                    movimientos
                    .slice(0,
                        calculaSalto(
                            dataSaldos.saldos,
                                movimientos
                            , 3, 28, 19, 4
                        )
                    ).map((d, j) => {
                        return (
                            <React.Fragment key={j}>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {processFechaGuion(d.Fecha)}
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc' style={{ textTransform: 'capitalize' }}>
                                    {
                                        capitalizeFirstLetter(cambioEfectivoPdf(d.descripPDF))
                                    }
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {cambioEfectivoPlan(d.Concepto)}
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo' ? '-' : ''}   {negativos(`$${nWithCommas(parseFloat(d.Monto).toFixed(2))}`)}
                                </div>
                            </React.Fragment>)
                    })

                    : null
            }

            {
                dataSaldos.saldos && movimientos.length >
                    calculaSalto(
                        dataSaldos.saldos,
                            movimientos
                        , 3, 28, 19, 4
                    )
                    ?

                    movimientos.slice(calculaSalto(
                        dataSaldos.saldos,
                            movimientos
                        , 3, 28, 19, 4
                    )).map((d, j) => {

                        if (j % 19 === 0) {
                            return (
                                <>
                                    <div className='col s12 html2pdf__page-break'></div>
                                    <div className='col s12' style={{ marginTop: '0.5rem' }} ></div>
                                    <div className='col s4'>
                                        <img src={logo} alt="logo" style={{ maxHeight: '55px' }} />
                                    </div>
                                    <div className='col s8'>
                                        <div className='row'>
                                            <div className='col s12 center-align' style={{
                                                background: '#002c5d',
                                                fontWeight: 'bold',
                                                fontSize: '18px',
                                                color: 'white', height: '55px', paddingTop: '1rem'
                                            }}>
                                                Plan de Pensiones
                                            </div>
                                            <div className='col s12 center-align ft-12'>
                                                Valores Mexicanos Casa de Bolsa, S.A. de C.V.
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col s4' style={{ paddingLeft: '0px' }}>
                                        <div className='boldText white-text' style={{
                                            background: '#002c5d',
                                            paddingLeft: '3px'
                                        }}>
                                            Movimientos de Mi Plan
                                        </div>
                                    </div>
                                    <div className='col s8'>
                                    </div>

                                    <div className='col s12 mt-1'></div>

                                    <div className='col s3 ft-10 center-align containerResumenBlue'>
                                        Fecha
                                    </div>
                                    <div className='col s3 ft-10 center-align containerResumenBlue'>
                                        Cuenta
                                    </div>
                                    <div className='col s3 ft-10 center-align containerResumenBlue'>
                                        Tipo de Movimiento
                                    </div>
                                    <div className='col s3 ft-10 center-align containerResumenBlue'>
                                        Monto
                                    </div>

                                </>
                            )
                        }

                        return (
                            <React.Fragment key={j}>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {processFechaGuion(d.Fecha)}
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc' style={{ textTransform: 'capitalize' }}>
                                    {
                                        capitalizeFirstLetter(cambioEfectivoPdf(d.descripPDF))
                                    }
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {d.Concepto}
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo' ? '-' : ''}   {negativos(`$${nWithCommas(parseFloat(d.Monto).toFixed(2))}`)}
                                </div>
                            </React.Fragment>)
                    })
                    : null
            }


            <div className='col s12 html2pdf__page-break'></div>
            <div className='col s12'>
                <span className='boldText tituloNota'>Nota: </span>
                <p className='notaText mt-0' style={{ marginBottom: '0px' }}>Si tienes alguna duda con respecto a este estado de beneficios o al funcionamiento del plan, no dudes en consultar el Departamento de Recursos Humanos</p>
            </div>
            <div className='col s12'>
                <span className='boldText tituloNota'>IMPORTANTE: </span>
                <p className='notaText mt-0'>El presente documento es una impresión del sistema Fondos en Línea, cuya licencia ha sido otorgada a Valores Mexicanos Casa de Bolsa, S.A. de C.V.,  (en adelante Valmex) el prestador del servicio de individualización de Fondos, quien ha firmado un contrato para la prestación del mismo con la Empresa mencionada en el presente documento, por lo que se prohíbe su reproducción no autorizada. La información aquí contenida es generada con base en la proporcionada por la Empresa de conformidad con lo pactado con la misma. Cualquier duda o inconformidad sobre la información aquí consignada, deberá de hacerse directamente con la Empresa señalada. Este reporte no constituye en modo alguno el estado de cuenta a que se refiere el artículo 203 de la Ley del Mercado de Valores, el cual es el único documento oficial donde quedan asentadas las operaciones realizadas y la posición de valores administrados al amparo del contrato de intermediación bursátil celebrado entre la Empresa y Valmex.  Cualquier tema relacionado con la información aquí presentada debe acudir con su Empresa. Valmex no se hace responsable por fallas, retrasos, omisiones y/o errores en la información reflejada en el presente documento informativo</p>
            </div>
        </div>
    )
}


export default PdfPlanDePensiones;