

import React, { useEffect, useState, useMemo } from "react"
import { useNavigate, useParams } from "react-router"
import { useSelector } from 'react-redux';
import {
    encryptAES,
    fechaDate, nWithCommas,
    processFechaNM,
    convertirCamelCase,
    eliminarAcentos,
    getNombreMes,
    stringDateToTimestamp,
    agrupaMovFecha,
    negativos,
    TEXT_FIN_SESION_JWT,
    capitalizeFirstLetter,
    cambiaEfectivo,
    obtenerUltimoDiaDelMesMilli
} from "../utils";
import { BASE_URL } from "../properties";
import { useDispatch } from "react-redux";
import { getSaldosFecha } from "../ws";
import { toogleLoad, setSaldosMovimientos, setTokenSesion, showModalFinSesion } from "../redux/slices/appslice";
import btnDescargar from '../assets/btnDescargaReporte.svg';
import M from 'materialize-css';
import PdfFondoAhorro from "../components/pfdFondoAhorro";
import html2pdf from 'html2pdf.js';
import PdfCajaDeAhorro from "../components/pdfCajaDeAhorro";
import PdfPlanDePensiones from "../components/pdfPlandePensiones";


function SaldosMovimientos() {
    const appData = useSelector((state) => state.appData)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();
    const [movToShow, setMovToShow] = useState({});
    const [optSelect, setOpt] = useState(1);
    const [optTipoAportacion, setTipoAportacion] = useState([]);
    const [optTipoMovimiento, setTipoMovimiento] = useState([]);
    const [fechasToSelect, setFechasToSelect] = useState([]);
    const [fechasToHasta, setFechasToHasta] = useState([]);
    const [movimientos, setMovimientos] = useState([]);

    const [titulo, setTitulo] = useState('')
    const [to_return, setToReturn] = useState('');

    const [filtros, setFiltros] = useState({ del: false });
    const [saldosFecha, setSaldosFecha] = useState(false);
    const [disabledMovimiento, setDisabledMovimientos] = useState(true);
    const [message, setMessage] = useState(false);
    const [currentTimeOut, setCurrentTimeOut] = useState(false);
    const [primerFecha, setPrimerFecha] = useState(false);

    useEffect(() => {
        if (message) {

            if (currentTimeOut) {
                clearTimeout(currentTimeOut);
            }

            setCurrentTimeOut(setTimeout(() => {
                setMessage(false);
            }, 10000));
        } else if (!message && currentTimeOut) {
            clearTimeout(currentTimeOut);
        }
    }, [message]);

    useEffect(() => {

        if (optTipoMovimiento && optTipoMovimiento.length) {
            var elems = document.getElementById('tipoMovimientoSelect');
            M.FormSelect.init(elems, {});
        }

    }, [optTipoMovimiento])

    useEffect(() => {

        if (fechasToSelect && Object.keys(fechasToSelect).length) {
            var elems = document.getElementById('del');
            M.FormSelect.init(elems, {});
        }

    }, [fechasToSelect])


    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: '/',
        movimientos: []
    });

    useEffect(() => {
        dispatch(toogleLoad(true));

        if (params.tipo && params.tipo === 'fondodeahorro') {

            getMovimientos({ titulo: 'Fondo de Ahorro', to_return: '/fondodeahorro' })
            getSF('Fondo de Ahorro');

        } else if (params.tipo && params.tipo === 'cajadeahorro') {

            getMovimientos({ titulo: 'Caja de Ahorro', to_return: '/cajadeahorro' })
            getSF('Caja de Ahorro');
        } else if (params.tipo && params.tipo === 'plandepensiones') {

            getMovimientos({ titulo: 'Fondo de Pensiones', to_return: '/plandepensiones' })
            getSF('Fondo de Pensiones');
        } else {
            navigate('/')
        }
        M.Modal.init(document.getElementById('pdfDownload'), {});

    }, [])

    // useEffect(() => {

    // }, [params])

    function aplicarFiltros() {

        setMessage(false);

        if (Object.keys(filtros).length) {


            if (!filtros || !filtros.del) {
                setMessage({
                    texto: 'Seleccione un periodo válido.',
                    tipo: 'error',
                    icono: 'info_outline'
                });
                return false;
            }
            let copyMovimientos = JSON.parse(JSON.stringify(movimientos));

            let filtroMovimientos = copyMovimientos.sort((a, b) => b.timestamp - a.timestamp).filter((v) => {

                if (filtros.del) {
            
                    if (v.timestamp >= filtros.del && v.timestamp <= obtenerUltimoDiaDelMesMilli(filtros.del)) {

                        if (!filtros.tipoAportacion || filtros.tipoAportacion === 'Todos' || !filtros.tipoMovimiento || filtros.tipoMovimiento === 'Todos') {

                            if (filtros.tipoMovimiento !== 'Todos' && v.Concepto === filtros.tipoMovimiento) {
                                return v;
                            } else if (!filtros.tipoMovimiento) {
                                return v;
                            } else if (filtros.tipoMovimiento == 'Todos') {
                                return v;
                            }

                        } else {
                            if (filtros.tipoMovimiento) {
                                if (v.Cuenta && v.Cuenta.toUpperCase() === filtros.tipoAportacion.toUpperCase() && v.Concepto.toUpperCase() === filtros.tipoMovimiento.toUpperCase()) {
                                    return v;
                                }
                            }
                        }



                    }
                } else {

                    if (!filtros.tipoAportacion || filtros.tipoAportacion === 'Todos' || !filtros.tipoMovimiento || filtros.tipoMovimiento === 'Todos') {

                        if (filtros.tipoMovimiento !== 'Todos' && v.Concepto === filtros.tipoMovimiento) {
                            return v;
                        } else if (filtros.tipoAportacion !== 'Todos' && v.Cuenta === filtros.tipoAportacion) {
                            return v;
                        } else if (filtros.tipoAportacion == 'Todos' && !filtros.tipoMovimiento) {
                            return v;
                        } else if (filtros.tipoMovimiento == 'Todos' && !filtros.tipoAportacion) {
                            return v;
                        } else if (filtros.tipoAportacion == 'Todos' && filtros.tipoMovimiento === 'Todos') {
                            return v;
                        }

                    } else {
                        if (filtros.tipoAportacion && filtros.tipoMovimiento) {
                            if (v.Cuenta && v.Cuenta.toUpperCase() === filtros.tipoAportacion.toUpperCase() && v.Concepto.toUpperCase() === filtros.tipoMovimiento.toUpperCase()) {
                                return v;
                            }
                        } else if (filtros.tipoAportacion && !filtros.tipoMovimiento) {
                            if (v.Cuenta && v.Cuenta.toUpperCase() === filtros.tipoAportacion.toUpperCase()) {
                                return v;
                            }
                        } else if (filtros.tipoMovimiento && !filtros.tipoAportacion) {
                            if (v.Concepto.toUpperCase() === filtros.tipoMovimiento.toUpperCase()) {
                                return v;
                            }
                        }
                    }
                }

            });

            setMovToShow(agrupaMovFecha(filtroMovimientos));
        }


    }

    function processMovimientos(movimientos) {
        if (movimientos.length) {


            let tipoAportacion = {};
            let tipoMovimientos = {}
            let fechasToSelect = {};
            let fechasToHasta = {};

            for (const mov of movimientos) {

                if (mov.hasOwnProperty('Concepto') && mov.hasOwnProperty('Cuenta')) {
                    mov['descrip'] = `${mov.Concepto} - ${mov.Cuenta}`;
                    mov['descripPDF'] = `${mov.Cuenta}`;
                }

                if (mov.hasOwnProperty('Concepto') && !mov.hasOwnProperty('Cuenta')) {
                    mov['descrip'] = `${mov.Concepto}`;
                    mov['descripPDF'] = `${mov.Concepto}`;
                }




                if (mov.hasOwnProperty('Concepto') && !tipoMovimientos[mov.Concepto]) {
                    tipoMovimientos[mov.Concepto] = []
                }

                if (mov.hasOwnProperty('Cuenta') && !tipoAportacion[mov.Cuenta]) {
                    tipoAportacion[mov.Cuenta] = []
                }


                mov['date'] = fechaDate(mov.Fecha);
                mov['timestamp'] = stringDateToTimestamp(mov.Fecha)

                const mes = mov['date'].getMonth() + 1; // Devuelve el mes (0-11)
                const año = mov['date'].getFullYear();

                if (!fechasToSelect[`${getNombreMes(mes - 1)} ${año}`]) {
                    fechasToSelect[`${getNombreMes(mes - 1)} ${año}`] = [
                        stringDateToTimestamp(mov.Fecha, true)
                    ]
                }

                if (!fechasToHasta[`${getNombreMes(mes - 1)} ${año}`]) {
                    fechasToHasta[`${getNombreMes(mes - 1)} ${año}`] = [
                        stringDateToTimestamp(mov.Fecha, false,)
                    ]
                }
            }

            setFechasToSelect(fechasToSelect);
            setFechasToHasta(fechasToHasta);
            setTipoAportacion(Object.keys(tipoAportacion));
            setTipoMovimiento(Object.keys(tipoMovimientos));
            setDisabledMovimientos(false)

            let copyMovimientos = JSON.parse(JSON.stringify(movimientos));

            if (copyMovimientos && copyMovimientos.length) {
                copyMovimientos = copyMovimientos.sort((a, b) => b.timestamp - a.timestamp);
            }


            if (optSelect === 1) { // Solo muestra los primeros 5 movimientos;
                const p5 = copyMovimientos.slice(0, 5);
                setMovToShow(agrupaMovFecha(p5));
            } else {

                setMovToShow(agrupaMovFecha(copyMovimientos));
            }

            setMovimientos(movimientos);
            return movimientos;
        } else {
            return []
        }
    }

    const getSF = async (titulo) => {
        // let saldoFecha = false;
        // if (!appData.saldosFecha) {
        let saldoFecha = await getSaldosFecha(appData, () => {
            dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
        });

        if (saldoFecha && saldoFecha.Datos && saldoFecha.Datos.length) {
            saldoFecha = saldoFecha.Datos;
        }

        if (saldoFecha && saldoFecha.JWT) {
            dispatch(setTokenSesion(saldoFecha.JWT.current_token))
        }

        // } else {
        //     saldoFecha = appData.saldosFecha;
        // }

        if (saldoFecha) {
            for (const dato of saldoFecha) {

                if (dato.Producto[0].TipoCliente.toUpperCase() === titulo.toUpperCase()
                ) {

                    setSaldosFecha(dato);

                    setTitulo(titulo);


                }
            }
        }


    }

    const getMovimientos = async ({ titulo, to_return }) => {

        // if (!appData.saldosMovimientos) {

        try {
            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "DeviceToken": "",
                "SistemaOperativo": "ios",
                "AppID": "SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };


            fetch(`${BASE_URL}/saldos/movimientos`, requestOptions)
                .then(response => response.json())
                .then((data) => {

                    if (data.BanderaExito) {
                        if (data.Datos && data.Datos.length) {
                            for (const dato of data.Datos) {

                                dispatch(setSaldosMovimientos(data.Datos));
                                if (dato.Producto[0].TipoCliente.toUpperCase() == titulo.toUpperCase()) {
                                    let movimientos = dato.MovimientoAhorro.concat(dato.MovimientoPrestamo)
                                    movimientos = JSON.parse(JSON.stringify(movimientos));

                                    setDataPage({ ...dataPage, titulo, to_return: to_return, movimientos: processMovimientos(movimientos) })

                                    setTitulo(titulo);
                                    setToReturn(to_return);
                                }
                            }
                        }



                        if (data.JWT && data.JWT.current_token) {
                            dispatch(setTokenSesion(data.JWT.current_token))
                        }
                        dispatch(toogleLoad(false));
                    } else {
                        if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                            dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
                        }
                    }
                });
        } catch (error) {
            dispatch(toogleLoad(false));
            setMessage({
                texto: 'Error en el servicio',
                tipo: 'error',
                icono: 'close'
            });
        }

    }

    const downloadPdf = () => {

        let id_toPDF = '';
        let title = '';

        if (titulo === 'Fondo de Ahorro') {
            id_toPDF = 'fdaPdf';
            title = 'Fondo de Ahorro.pdf';
        }

        if (titulo === 'Caja de Ahorro') {
            id_toPDF = 'cdaPdf';
            title = 'Caja de Ahorro.pdf';
        }

        if (titulo === 'Fondo de Pensiones') {
            id_toPDF = 'penPdf';
            title = 'Plan de Pensiones.pdf';
        }

        const element = document.getElementById(id_toPDF);

        // Configuración de html2pdf
        const options = {
            margin: 1,
            filename: title,
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
            pagebreak: { mode: ['css', 'legacy'] }
        };

        // Generar el PDF
        html2pdf().set(options).from(element).save();

        setMessage({
            texto: '¡Listo! Se ha descargado el reporte en tu dispositivo.',
            tipo: 'exito',
            icono: 'check'
        });
    };

    return (
        <div className="container">
            <div className="row mt-2">
                {
                    message ?
                        <div className={`col s12 toastmessage_${message.tipo} boldText z-depth-2`}>
                            <i className="material-icons left">{message.icono}</i> {message.texto}
                        </div>
                        : null
                }

                <div className="col s12 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> {titulo === 'Fondo de Pensiones' ? 'Plan de Pensiones' : titulo}</p>
                    <h5 className="txtBold">Mis Saldos y Movimientos</h5>
                </div>
            </div>
            <div className="row mt-1 wrapperFormMovimientos">

                <div className="col s12 left-align boldText ft-20">
                    Filtrar Movimientos:
                </div>

                <div className="col s5 left-align labelTxt mt-1">
                    Periodo
                </div>
                <div className="col s5 left-align labelTxt mt-1">
                    Movimiento
                </div>

                <div className="input-field col s12 m5">

                    <select id="del" className="notranslate"

                        disabled={!Object.keys(fechasToSelect).length}

                        onChange={(e) => {

                            setFiltros({ ...filtros, del: parseInt(e.target.value) });
                            setPrimerFecha(e.target.value);
                        }}
                    >
                        <option>Seleccionar</option>
                        {
                            Object.keys(fechasToSelect).length && Object.keys(fechasToSelect).map((v, i) => <option value={fechasToSelect[v][0]} key={i}>{v}</option>)
                        }
                    </select>

                </div>


                <div className="input-field col s12 m5">
                    <select id="tipoMovimientoSelect" className="notranslate"
                        disabled={disabledMovimiento}
                        onChange={(e) => {
                            if (e.target.value && e.target.value !== "") {
                                setFiltros({ ...filtros, tipoMovimiento: e.target.value })
                            } else {
                                setFiltros({ ...filtros, tipoMovimiento: false })
                            }

                        }}

                    >
                        <option value="">Selecciona una opción</option>
                        <option value="Todos">Todos</option>
                        {
                            optTipoMovimiento.length ?
                                optTipoMovimiento.map((v, i) => <option value={v} key={i}>{v}</option>)
                                : null
                        }

                    </select>
                </div>


                <div className="col s12 m2 center-align">
                    <button className="btn btnAction ft-14 txtBold mt-2 waves-effect waves-light"

                        onClick={() => {
                            aplicarFiltros()
                        }}
                    >Consultar</button>
                </div>

            </div>
            <div className="row mt-2">

                <div className="col s6 left-align linksC ft-16 txtBold">
                    {/* Resumen de mi Fondo */}
                </div>
                <div className="col s6 right-align">
                    <img src={btnDescargar} alt="descargar" className="cursorPointer waves-effect"

                        onClick={() => {
                            if (filtros && filtros.del) {
                                let elem = document.getElementById('pdfDownload')
                                if (elem) {
                                    let instanceM = M.Modal.getInstance(elem);
                                    if (instanceM) {
                                        instanceM.open();
                                    }
                                }
                            }else{
                                setMessage({
                                    texto: 'Seleccione un periodo válido.',
                                    tipo: 'error',
                                    icono: 'info_outline'
                                });
                            }

                        }}
                    ></img>
                </div>
                {/* {
                    saldosFecha ?
                        <React.Fragment>
                            <div className="col s12 ft-10 ">
                                Al {processFechaActual()}
                            </div>

                            <div className="col s12 m3 mt-1">
                                <div className="row cardSaldoActual br-8">
                                    <div className="col s12 ft-14">
                                        Aportaciones Totales
                                    </div>
                                    <div className="col s12 ft-16">
                                        +${nWithCommas(parseFloat(saldosFecha.aportaciones).toFixed(2))}
                                    </div>
                                </div>
                            </div>

                            <div className="col s12 m3 mt-1">
                                <div className="row cardSaldoActual br-8">
                                    <div className="col s12 ft-14">
                                        Rendimientos
                                    </div>
                                    <div className="col s12 ft-16">
                                        +${nWithCommas(parseFloat(saldosFecha.rendimiento).toFixed(2))}
                                    </div>
                                </div>
                            </div>

                            <div className="col s12 m3 mt-1">
                                <div className="row  cardSaldoActual br-8">
                                    <div className="col s12 ft-14">
                                        Saldo de Préstamo
                                    </div>
                                    <div className="col s12 ft-16">
                                        -${nWithCommas(parseFloat(saldosFecha.SaldoPrestamos).toFixed(2))}
                                    </div>
                                </div>
                            </div>

                            <div className="col s12 m3 mt-1">
                                <div className="row cardSaldActualtotal">
                                    <div className="col s12 ft-14">
                                        Saldo Total
                                    </div>
                                    <div className="col s12 ft-16">
                                        ${nWithCommas(parseFloat(saldosFecha.SubTotal).toFixed(2))}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>

                        : null
                } */}

                {
                    movToShow && Object.keys(movToShow).length ?
                        <React.Fragment>
                            {
                                Object.keys(movToShow).reverse().map((v, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <div className="col s12 left-align ft-10 vlmx-navy-80 txtBold mt-1">
                                                {processFechaNM(v)}
                                            </div>
                                            {
                                                movToShow[v].length ?
                                                    movToShow[v].map((d, j) => {

                                                        return (
                                                            <div className="col s12" key={j}>
                                                                <div className={`row  mb-0 
                                                        ${d.Monto < 0 || (d.Concepto && eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo') ? 'tipo_negativo' : 'tipo_normal'}
                                                        `} >
                                                                    <div className="col s6 left-align ft-12 vlmx-navy-80" style={{ textTransform: 'capitalize' }}>
                                                                        {
                                                                            cambiaEfectivo(capitalizeFirstLetter(convertirCamelCase(d.descrip)))
                                                                        }

                                                                    </div>
                                                                    <div className="col s6 right-align ft-14 vlmx-navy-80">
                                                                        {eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo' ? '-' : ''}   {negativos(`$${nWithCommas(parseFloat(d.Monto).toFixed(2))}`)}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                    : null
                                            }

                                        </React.Fragment>
                                    )
                                })
                            }
                            <div style={{ height: '400px' }} ></div>
                        </React.Fragment>

                        : <div className="col s12 mt-1 left-align txtBold" style={{ height: '400px' }} >
                            No se encontró información
                        </div>
                }

            </div>

            <div id="pdfDownload" className="modal">
                <div className="modal-content">
                    <div className="col s12 right-align">
                        <button className="btn btnAction"
                            onClick={() => {
                                downloadPdf();
                            }}
                        ><i className="material-icons">print</i></button>
                    </div>
                    <div className="col s12 mt-1"></div>
                    {
                        titulo === 'Fondo de Ahorro' ?
                            <PdfFondoAhorro movToShow={movToShow} id='fdaPdf' primerFecha={primerFecha} />
                            : null
                    }
                    {
                        titulo === 'Caja de Ahorro' ?
                            <PdfCajaDeAhorro movToShow={movToShow} id='cdaPdf' primerFecha={primerFecha} />
                            : null
                    }
                    {
                        titulo === 'Fondo de Pensiones' ?
                            <PdfPlanDePensiones movToShow={movToShow} id='penPdf' primerFecha={primerFecha} />
                            : null
                    }

                </div>

            </div>

        </div>
    )

}


export default SaldosMovimientos;